import React from 'react';
import Image from 'next/image';
import footerMenu from './FooterMenu';

import logo278 from '../../public/images/logo278.png';
import qrcode from '../../public/images/Hidream-qrcode.png';
import icp from '../../public/images/icp.png';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="bb">
          <div className="top">
            <div className="footer-content">
              <div className="row left">
                {footerMenu.map((menu, index) => (
                  <dl className="col" key={index}>
                    <dt className="linkname">{menu.title}</dt>

                    {menu.subLink.map((sub, index) => (
                      <dd key={index}>
                        <a href={sub.url}>{sub.name}</a>
                      </dd>
                    ))}
                  </dl>
                ))}
              </div>

              <div className="media right">
                <div className="media-wrapper">
                  <div className="logo">
                    <Image src={logo278} alt="logo" />
                  </div>

                  <div className="qrcode">
                    <Image src={qrcode} alt="公众号" />
                  </div>

                  <span>关注Hidream公众号</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom">
            <div className="address">
              <span>地址：浙江省杭州市萧山区皓月路159号诺德财富中心A座3301室</span>
            </div>

            <div className="copyright">
              <span className="icp-1">Hidream讯梦国际版权所有</span>
              <span className="icp-2">&nbsp;Copyright&nbsp;©&nbsp;2021</span>
              <span className="icp-3">
                <a
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902003051"
                  style={{ color: ' #96979e' }}
                  rel="noreferrer"
                >
                  <Image src={icp} alt="icp" />
                  浙公网安备&nbsp;33010902003051号
                </a>
              </span>
              <span className="icp-4">
                <a
                  target="_blank"
                  href="https://beian.miit.gov.cn/"
                  style={{ color: ' #96979e' }}
                  rel="noreferrer"
                >
                  浙ICP备2021031902号
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
