const categories = [
  {
    name: '首页',
    href: '/',
    submenu: [],
    key: '/home',
  },
  {
    name: 'Hidream服务',
    key: '/servive',
    href: '/ourservice',
    submenu: [
      {
        name: '广告投放',
        key: '/advertising',
        href: '/advertising',
        icon: (
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-hidreamguanggaotoufang01"></use>
          </svg>
        ),
      },
      {
        name: '品牌孵化',
        key: '/operation',
        href: '/operation',
        icon: (
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-hidreamdaiyunyingfuhua01"></use>
          </svg>
        ),
      },
    ],
  },
  {
    name: 'Google',
    key: '/google',
    href: '/google',
    submenu: [],
  },
  {
    name: 'Facebook',
    key: '/facebook',
    href: '/facebook',
    submenu: [],
  },
  {
    name: '媒体资讯',
    key: '/media',
    href: '/media',
    submenu: [],
  },
  {
    name: '关于我们',
    key: '/about',
    href: '/about',
    submenu: [
      {
        name: '关于我们',
        key: '/aboutus',
        href: '/aboutus',
        icon: (
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-hidreamtuandui-copy"></use>
          </svg>
        ),
      },
      {
        name: '加入我们',
        key: '/joinus',
        href: '/joinus',
        icon: (
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-hidreamhezuoguanxi-xianxing-copy"></use>
          </svg>
        ),
      },
    ],
  },
  {
    name: '联系我们',
    key: '/contactus',
    href: '/contactus',
    submenu: [],
  },
  // {
  //   name: '春风惠企',
  //   key: '/activity',
  //   href: '/activity',
  //   submenu: [],
  // },
];

export default categories;
