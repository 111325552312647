const footerMenu = [
  {
    title: '关于Hidream',
    subLink: [
      {
        name: '关于我们',
        url: '/aboutus',
      },
      {
        name: '加入我们',
        url: '/joinus',
      },
      {
        name: '联系我们',
        url: '/contactus',
      },
    ],
  },
  {
    title: '最新动态',
    subLink: [
      {
        name: '企业新闻',
        url: '/media',
      },
      // {
      //   name: '活动分享',
      //   url: '/activity',
      // },
      // {
      //   name: '成功案例',
      //   url: '/',
      // },
    ],
  },
  {
    title: '友情链接',
    subLink: [
      {
        name: '蓝色星期五',
        url: 'https://www.bluefriday.cn/',
      },
    ],
  },
];

export default footerMenu;
