import React from 'react';
import { BackTop } from 'antd';

export default function backTopComponent() {
  return (
    <BackTop className="backTop">
      <div className="backTopContent">
        <svg className="icon" aria-hidden="true">
          <use xlinkHref="#icon-hidreamhuidaodingbu"></use>
        </svg>
        <span>回顶部</span>
      </div>
    </BackTop>
  );
}
