import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import categories from './navigation';

const { SubMenu } = Menu;

function Navbar() {
  const router = useRouter();
  let defaulS = [];
  defaulS.push(router.pathname);

  return (
    <nav id="nav" className="navbar">
      <Menu
        selectedKeys={defaulS}
        mode="horizontal"
        builtinPlacements={{
          bottomLeft: {
            points: ['tc', 'bc'],
            overflow: {
              adjustX: 1,
              adjustY: 1,
            },
            offset: [0, 5],
          },
        }}
      >
        {categories.map((category, index) => {
          return category.submenu.length > 0 ? (
            <SubMenu key={category.key} title={category.name}>
              <Menu.ItemGroup>
                {category.submenu.map((sub_category, index) => {
                  const IconSvg = () => sub_category.icon;
                  const HeartIcon = (props) => (
                    <Icon component={IconSvg} {...props} />
                  );

                  return (
                    <Menu.Item key={sub_category.key} icon={<HeartIcon />}>
                      <Link href={sub_category.href}>
                        <a>{sub_category.name}</a>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.ItemGroup>
            </SubMenu>
          ) : (
            <Menu.Item key={category.key}>
              <Link href={category.href}>
                <a>{category.name}</a>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </nav>
  );
}

export default Navbar;
