import Head from 'next/head';
import Script from 'next/script';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BackTop from '../components/Backtop';

import '../styles/animate.css';
import 'antd/dist/antd.css';
import '../styles/tailwind.css';
import 'video-react/dist/video-react.css';
import '../styles/globals.css';
import '../styles/index.scss';

function MyApp({ Component, pageProps }) {
  return (
    <div>
      <Head>
        <title>Hidream讯梦国际-专注中国品牌海外推广</title>
        <meta
          name="keywords"
          content="跨境营销|外贸|电商服务|广告投放|代运营|品牌运营|社媒运营"
        />
        <meta
          name="description"
          content="Hidream讯梦国际,跨境电商业内专业的营销全案公司，专注中国品牌海外推广的跨境出海服务商。"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Script
        id="ali-iconfont"
        src="//at.alicdn.com/t/font_2861414_mm2t68wae3b.js"
      />
      {process.env.ENV == 'production' && (
        <Script id="bdtj">
          {`
            var _hmt = _hmt || [];
              (function () {
                var hm = document.createElement('script');
                hm.src = 'https://hm.baidu.com/hm.js?dde9d1887214aa5402eafcbb62b33c89';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(hm, s);
              })();
          `}
        </Script>
      )}
      <Script id="http-convert">
        {`
          var protocol = window.location.protocol;
          if (protocol == "http:" && location.href.indexOf("localhost") == -1) {
            window.location.href = window.location.href.replace("http", "https");
          }
        `}
      </Script>
      <Script id="browser-redirect">
        {`
          var browser = "";
          var host = window.location.host;
          function browserRedirect() {
            var sUserAgent = navigator.userAgent.toLowerCase();
            var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
            var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
            var bIsMidp = sUserAgent.match(/midp/i) == "midp";
            var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
            var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
            var bIsAndroid = sUserAgent.match(/android/i) == "android";
            var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
            var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
            if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
              browser = "phone";
            } else {
              browser = "pc";
            }
          }
          browserRedirect();
          if (browser == "pc" && host.indexOf("m.hidream.net") != -1) {
            window.location.href = window.location.href.replace("m.hidream.net", "www.hidream.net");
          } else {
            if (browser == "phone" && host.indexOf("www.hidream.net") != -1) {
              window.location.href = window.location.href.replace("www.hidream.net", "m.hidream.net");
            }
          }
        `}
      </Script>
      <Header />
      <Component {...pageProps} />
      <Footer />

      <BackTop />
    </div>
  );
}

export default MyApp;
