import React, { Component } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Navbar from './navbar';

import Logo from '../../public/images/logo.png';

class Header extends Component {
  componentDidMount() {
    this.handleOnResize();
    window.onresize = () => {
      this.handleOnResize();
    };
  }

  handleOnResize = () => {
    document.documentElement.style.setProperty(
      '--header-height',
      `${document.getElementById('header').offsetHeight}px`
    );
  };

  render() {
    return (
      <header id="header" className="sticky top-0">
        <div className="m-auto flex flex-col items-center justify-center pt-4 xl:max-w-screen-xl xl:flex-row xl:justify-between xl:pt-0">
          <Link href="/">
            <a className="logo flex items-center text-white">
              <Image src={Logo} alt="logo" />
              <span className="ml-4 text-sm">讯梦国际</span>
            </a>
          </Link>

          <Navbar />
        </div>
      </header>
    );
  }
}

export default Header;
